<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showModalNewFGOS" :disabled="!$auth.isLoggedIn()">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1 || !$auth.isLoggedIn()"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0 || !$auth.isLoggedIn()"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
      <a-upload
        class="mr-2"
        :file-list="fileList"
        :remove="handleRemove"
        :before-upload="beforeUpload"
      >
        <a-button>
          <upload-outlined></upload-outlined>
          Выберите файл
        </a-button>
      </a-upload>
      <a-button
        type="primary"
        :disabled="fileList.length === 0"
        :loading="uploading"
        style="margin-top: 16px"
        @click="handleUpload"
      >
        {{ uploading ? "Загружаю" : "Начать загрузку" }}
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    />

    <a-modal width="100" v-model:visible="visibleshortFGOS" title="ФГОС" @ok="handleOkshortFGOS">
        <a-form-item label="Наименование" >
          <a-select v-model:value="FGOSshortFormState.FGOSTitle" :disabled="!$auth.isLoggedIn()">
            <a-select-option
              v-for="fgos in fgoses"
              :key="fgos.title"
              v-bind:value="fgos.title"
            >
              {{ fgos.title }}
            </a-select-option>
          </a-select>
        </a-form-item>    
    </a-modal>

    <a-modal width="100" v-model:visible="visibleFGOS" title="ФГОС" @ok="handleOkFGOS">
      <a-form ref="FGOSForm" :model="FGOSFormState">
        <a-form-item label="Наименование">
          <a-input
            v-model:value="FGOSFormState.FGOSTitle"
            :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      
        <a-form-item label="Профиль" >
          <a-select v-model:value="FGOSFormState.FGOSProfSpecialization" :disabled="!$auth.isLoggedIn()">
            <a-select-option
              v-for="profSpecialization in profSpecializations"
              :key="profSpecialization.id"
              v-bind:value="profSpecialization.id"
            >
              {{ profSpecialization.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Наименование ПООП СПО" >
          <a-select v-model:value="FGOSFormState.FGOSProgramTitle" :disabled="!$auth.isLoggedIn()">
            <a-select-option
              v-for="programTitle in programTitles"
              :key="programTitle.id"
              v-bind:value="programTitle.id"
            >
              {{ programTitle.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Уровень программы" >
          <a-select v-model:value="FGOSFormState.FGOSProgramLevel" :disabled="!$auth.isLoggedIn()">
            <a-select-option
              v-for="programLevel in programLevels"
              :key="programLevel.id"
              v-bind:value="programLevel.id"
            >
              {{ programLevel.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
              label="Квалификация выпускника"
              class="table-item start-table-section"
            >
              <div class="card-header">
                <GraduateQualifications
                  v-if="!isNew"
                  :FGOSID="this.FGOSFormState.FGOSID"
                  :FGOSCHANGE="0"
                  :scroll="{ y: 220 }"
                />
                <span v-else>Доступно после сохранения</span>
              </div>
            </a-form-item>
    </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";
import request from "umi-request";
import { message } from "ant-design-vue";
import GraduateQualifications from "@/views/DropDownEntities/GraduateQualifications.vue";

export default {
  name: "FGOS",
    props: {
    UGPSID: { 
      type: Number
    },
    scroll: {
      type: Object,
      default () {
        return {
          x: 'calc(100%)',
          y: '100%'
        }
      }
    }
  },
  components: {
    UploadOutlined,
    GraduateQualifications,
  },
    computed: {    
    isNew() {
      return !this.FGOSFormState.FGOSID;
    },
    },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "title", title: "Наименование" },
        { dataIndex: "profSpecialization.title", title: "Профиль" },
        { dataIndex: "programLevel.title", title: "Уровень программы" },
        { dataIndex: "programTitle.title", title: "Лаборатория" },
      ],
      gridData: [],
      visibleFGOS: false,
      visibleshortFGOS: false,
      thisFGOS: [],

      FGOSFormState: {
        FGOSID: undefined,
        FGOSTitle: undefined,
        FGOSProfSpecialization: undefined,
        FGOSProgramTitle: undefined,
        FGOSProgramLevel: undefined,
      },
      FGOSshortFormState: {
        FGOSTitle: undefined,
      },      
      selectedRowKeys: [],
      selectedRows: [],
      fileList: [],
      uploading: ref(false),
      programLevels: [],
      programTitles: [],
      profSpecializations: [],

    };
  },
  methods: {

    async reload() {
      this.fgoses = (await axios.get("fgos")).data;
      this.programLevels = (await axios.get("program_level")).data;
      this.programTitles = (await axios.get("program_title")).data;
      this.profSpecializations = (await axios.get("prof_specialization")).data;

      if (this.UGPSID == undefined) {
        this.gridData = (await axios.get("FGOS")).data;
      } else {
        if (this.UGPSID == -1) {
          this.gridData = [];
        } else {
          this.gridData = (
            await axios.get(
              `FGOS/ugps/${this.UGPSID}`
            )
          ).data;
        }
      }
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index);
          this.thisFGOS = record;
          this.FGOSFormState.FGOSID = record.id;
          this.FGOSFormState.FGOSTitle = record.title;
          if (record.programLevel != null)
          this.FGOSFormState.FGOSProgramLevel = record.programLevel.id;
          if (record.profSpecialization != null)
          this.FGOSFormState.FGOSProfSpecialization = record.profSpecialization.id;
          if(record.programTitle != null)
          this.FGOSFormState.FGOSProgramTitle = record.programTitle.id;

          console.log("thisFGOS is - ", this.thisFGOS);
          this.showModalFGOS();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    showModalFGOS() {
      this.visibleFGOS = true;
    },
    showModalNewFGOS() {
      if(this.UGPSID == undefined)
      {
      this.resetForm();
      this.visibleFGOS = true;
      }
      else{
        this.FGOSshortFormState.FGOSTitle = undefined;
        this.visibleshortFGOS = true;
      }
    },
    resetForm() {
      this.FGOSFormState.FGOSID = undefined;
      this.FGOSFormState.FGOSTitle = undefined;
      this.FGOSFormState.FGOSProfSpecialization = undefined;
      this.FGOSFormState.FGOSProgramTitle = undefined;
      this.FGOSFormState.FGOSProgramLevel = undefined;
    },
    async handleOkshortFGOS() {
      this.visibleshortFGOS = false;
        if (this.FGOSshortFormState.FGOSTitle !== undefined && this.UGPSID !== undefined) {
          try {
            await axios.put("FGOS", {
              title: this.FGOSshortFormState.FGOSTitle,
              delete: 0,
              ugpsid: this.UGPSID,
            });
          } finally {
            this.FGOSshortFormState.FGOSTitle = undefined;
            await this.reload();
          }
      }
    },
    async handleOkFGOS() {
      console.log("FGOSModal.");
      this.visibleFGOS = false;
      if (this.FGOSFormState.FGOSTitle !== undefined) {
        if (this.FGOSFormState.FGOSID == undefined) {
          try {
            await axios.post("FGOS", {
              title: this.FGOSFormState.FGOSTitle,
              profSpecialization: this.FGOSFormState.FGOSProfSpecialization,
              programLevel: this.FGOSFormState.FGOSProgramLevel,
              programTitle: this.FGOSFormState.FGOSProgramTitle,
            });
          } finally {
            this.resetForm();
            await this.reload();
          }
        } else {
          try {
            await axios.put("FGOS", {
              id: this.FGOSFormState.FGOSID,
              title: this.FGOSFormState.FGOSTitle,
              profSpecialization: this.FGOSFormState.FGOSProfSpecialization,
              programLevel: this.FGOSFormState.FGOSProgramLevel,
              programTitle: this.FGOSFormState.FGOSProgramTitle,
            });
          } finally {
            this.resetForm();
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      if (this.UGPSID == undefined){
        try {
          await axios.delete("FGOS", {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            data: selectedRowKeys,
          });
        } finally {
          selectedRowKeys = undefined;
          await this.reload();
        }
      }
      else{
          try {
          await axios.put("FGOS", {
            ugpsid: this.UGPSID,
            delete: 1,
            deleteint: selectedRowKeys,
          });
        } finally {
          this.resetForm();
          await this.reload();
        }
      }
    },
    async editEntity(selectedRows) {
      console.log("THIS LOG", selectedRows);

      this.FGOSFormState.FGOSID = selectedRows[0].id;
      this.FGOSFormState.FGOSTitle = selectedRows[0].title;
      this.FGOSFormState.FGOSProfSpecialization = selectedRows[0].profSpecialization;
      this.FGOSFormState.FGOSProgramTitle = selectedRows[0].programTitle;
      this.FGOSFormState.FGOSProgramLevel = selectedRows[0].programLevel;

      this.showModalFGOS();
    },
      handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  },
  beforeUpload(file) {
    this.fileList.push(file);
    return false;
  },
  async handleUpload() {
    const formData = new FormData();
    this.fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    this.uploading = true;
    request(axios.defaults.baseURL + "/Upload/Fgos", {
      method: "post",
      data: formData,
    })
      .then(() => {
        this.fileList = [];
        this.uploading = false;
        message.success("Загрузка прошла успешно.");
        this.reload();
      })
      .catch(() => {
        this.uploading = false;
        message.error("Ошибка Загрузки.");
      });
  },
  },
  async created() {
    await this.reload();
  },
};
</script>
