<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" :disabled="!$auth.isLoggedIn()">
        <template #icon
          ><i class="fa fa-plus" @click="showModalNewGraduateQualifications"
        /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length != 1)||(!$auth.isLoggedIn())"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length == 0)||(!$auth.isLoggedIn())"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
        </a-button>
              <a-upload
          class="mr-2"
          :file-list="fileList"
          :remove="handleRemove"
          :before-upload="beforeUpload"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            Выберите файл
          </a-button>
        </a-upload>
        <a-button
          type="primary"
          :disabled="fileList.length === 0"
          :loading="uploading"
          style="margin-top: 16px"
          @click="handleUpload"
        >
          {{ uploading ? "Загружаю" : "Начать загрузку" }}
        </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="scroll"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
      :class="gridData.length < 5 ? 'hide-native-scrollbar' : ''"
    />

    <a-modal
      width="100"
      v-model:visible="visibleGraduateQualifications"
      title="Квалификации выпускников"
      @ok="handleOkGraduateQualifications"
    >
      <a-form
        ref="GraduateQualificationsForm"
        :model="GraduateQualificationsFormState"
      >
      <a-form-item label="Наименование">
        <a-form ref="programForm" :model="GraduateQualificationsFormState">
          <a-form-item label="Наименование">
            <a-input v-if = "this.FGOSID == undefined"
              v-model:value="GraduateQualificationsFormState.GraduateQualificationsTitle" :disabled="!$auth.isLoggedIn()"
            />
            <a-select v-else
            v-model:value="GraduateQualificationsFormState.GraduateQualificationsTitle" :disabled="!$auth.isLoggedIn()"
            placeholder="выберите квалификацию выпускника"
          >
            <a-select-option
              v-for="record in allQualifications"
              :key="record.id"
              v-bind:value="record.title"
            >
              {{ record.title }}
            </a-select-option>
          </a-select>
          </a-form-item>
         </a-form>
      </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import request from "umi-request";
import { message } from "ant-design-vue";

export default {
  name: "GraduateQualifications",
  props: {
    FGOSID: { 
      type: Number
    },
    FGOSCHANGE: { 
      type: Number
    },
    scroll: {
      type: Object,
      default () {
        return {
          x: 'calc(100%)',
          y: '100%'
        }
      }
    }
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID", width: 80 },
        { dataIndex: "title", title: "Наименование" },
      ],
      gridData: [],
      visibleGraduateQualifications: false,
      thisGraduateQualifications: [],
      GraduateQualificationsFormState: {
        GraduateQualificationsID: undefined,
        GraduateQualificationsTitle: undefined,
        GraduateQualificationsFGOSID: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
      allQualifications: [],
            fileList: [],
    };
  },
  watch: {
    FGOSID: {
      handler() {
        this.reload();
      },
      function() {},

      immediate: true,
    },
  },
  methods: {
    async reload() {
      this.allQualifications = (await axios.get("graduate_qualifications")).data;
      if (this.FGOSID == undefined) {
        this.gridData = (await axios.get("graduate_qualifications")).data;
      } else {
        if (this.FGOSID == 0 && this.FGOSCHANGE == 1) {
          this.gridData = [];
        } else {
          this.gridData = (
            await axios.get(
              `graduate_qualifications/fgos/${this.FGOSID}`
            )
          ).data;
        }
      }
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisGraduateQualifications = record;
          this.GraduateQualificationsFormState.GraduateQualificationsTitle =  record.title;
          this.GraduateQualificationsFormState.GraduateQualificationsID =  record.id;
          this.GraduateQualificationsFormState.GraduateQualificationsFGOSID = this.FGOSID;
          console.log(this.thisGraduateQualifications);
          this.showModalGraduateQualifications();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalGraduateQualifications() {
      this.visibleGraduateQualifications = true;
    },
    resetForm() {
      this.GraduateQualificationsFormState.GraduateQualificationsID = undefined;
      this.GraduateQualificationsFormState.GraduateQualificationsTitle = undefined;
      this.GraduateQualificationsFormState.GraduateQualificationsFGOSID = undefined;
    },
    showModalNewGraduateQualifications() {
      this.resetForm();
      if (this.FGOSID != undefined)
        this.GraduateQualificationsFormState.GraduateQualificationsFGOSID = this.FGOSID;
      this.visibleGraduateQualifications = true;
    },
    async handleOkGraduateQualifications() {
      console.log("GraduateQualificationsModal.");
      this.visibleGraduateQualifications = false;
      if (
        this.GraduateQualificationsFormState.GraduateQualificationsTitle !== undefined
      ) {
        if (
          this.GraduateQualificationsFormState.GraduateQualificationsID == undefined
        ) {
          try {
            await axios.post("graduate_qualifications", {
              title: this.GraduateQualificationsFormState.GraduateQualificationsTitle,
              fgosid: this.GraduateQualificationsFormState.GraduateQualificationsFGOSID,
            });
          } finally {
            this.resetForm();
            await this.reload();
          }
        } else {
          console.log("GraduateQualificationsModal.");
          console.log({
            id: this.GraduateQualificationsFormState.GraduateQualificationsID,
            title: this.GraduateQualificationsFormState.GraduateQualificationsTitle,
          });
          try {
            await axios.put("graduate_qualifications", {
              id: this.GraduateQualificationsFormState.GraduateQualificationsID,
              title: this.GraduateQualificationsFormState.GraduateQualificationsTitle,
              fgosid: this.GraduateQualificationsFormState.GraduateQualificationsFGOSID,
                delete: 0,
                deleteint: undefined,
            });
          } finally {
            this.resetForm();
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      if (this.FGOSID == undefined)
      {
        try {
        await axios.delete("graduate_qualifications", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
      }
      else {
            try {
            await axios.put("graduate_qualifications", {
              id: this.GraduateQualificationsFormState.GraduateQualificationsID,
              title: undefined,
              fgosid: this.FGOSID,
                delete: 1,
                deleteint: selectedRowKeys,
            });
          } finally {
            this.resetForm();
            await this.reload();
          }
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.GraduateQualificationsFormState.GraduateQualificationsID = selectedRows[0].id;
      this.GraduateQualificationsFormState.GraduateQualificationsTitle = selectedRows[0].title;
      this.GraduateQualificationsFormState.GraduateQualificationsFGOSID = selectedRows[0].fgosid;
      this.GraduateQualificationsFormState.GraduateQualificationsItem = selectedRows[0].item;
      this.showModalGraduateQualifications();
    },
    handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList.push(file);
      return false;
    },
    async handleUpload() {
      const formData = new FormData();
      this.fileList.forEach((file) => {
        formData.append("files[]", file);
      });
      this.uploading = true;
      request(axios.defaults.baseURL + "/Upload/GraduateQualification", {
        method: "post",
        data: formData,
      })
        .then(() => {
          this.fileList = [];
          this.uploading = false;
          message.success("Загрузка прошла успешно.");
          this.reload();
        })
        .catch(() => {
          this.uploading = false;
          message.error("Ошибка Загрузки.");
        });
  },
  },
  async created() {
    await this.reload();
  },
  async beforeCreated() {
    await this.reload();
  },
};
</script>